import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import FeedbackTable from '../../components/feedback/FeedbackTable';

function FeedbackPage() {
  return (
    <Container maxWidth="lg">
      <PageHeading />
      <FeedbackTable />
    </Container>
  );
}

export default FeedbackPage;
