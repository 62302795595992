import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { Typography } from '@mui/material';

function FeedbackTable() {
  const [feedbacks, setFeedbacks] = useState([]);

  const dispatch = useDispatch();
  const getFeedbacks = async () => {
    try {
      const response = await api.get('/event/feedback');

      setFeedbacks(response.data.data.feedbacks);
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        description: 'Wiroc ID',
        type: 'string',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'user',
        headerName: 'Name',
        description: 'feedback',
        type: 'string',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => <Box>{params.value?.name}</Box>,
      },

      {
        field: 'feedback',
        headerName: 'Feedback',
        description: 'feedback',
        type: 'string',
        minWidth: 400,
        flex: 1,
        renderCell: (params) => (
          <Box
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              lineHeight: '1.5',
            }}
          >
            {params.value}
          </Box>
        ),
      },
      {
        field: 'rating',
        headerName: 'Rating',
        description: 'Rating',
        type: 'String',
        minWidth: 100,
        flex: 1,
      },
    ],
    []
  );

  useEffect(() => {
    getFeedbacks();
  }, []);

  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" component="h4">
          Feedback
        </Typography>

        {/* Subheading */}
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'gray', marginBottom: 2 }}
        >
          Feedback submitted by users.
        </Typography>
      </Box>
      <Paper
        sx={{
          height: 600,
          width: '100%',
          marginTop: 2,
          // boxShadow: 8,
          padding: 4,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          rows={feedbacks}
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              lineHeight: '1.5',
              paddingTop: '8px',
              paddingBottom: '8px',
            },
          }}
        />
      </Paper>
    </>
  );
}

export default FeedbackTable;
