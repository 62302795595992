import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import NotificationTable from '../../components/notification/NotificationTable';

function NotificationPage() {
  return (
    <Container maxWidth="lg">
      <PageHeading />
      <NotificationTable />
    </Container>
  );
}

export default NotificationPage;
