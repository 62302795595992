import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import AddCredForm from './AddCredForm';

export const Cred = () => {
  return (
    <Box sx={{ flexGrow: 1, marginTop: 2 }}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sm>
          <Card>
            <CardHeader
              title="Security Code"
              subheader="Update Security Codes"
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2 }}>
              <AddCredForm />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cred;
