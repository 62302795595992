import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { isLoading, isAuthenticated } = auth;
  return isLoading ? (
    <Box sx={{ width: '110vw', heiht: '100vh', display: 'flex' }}>
      <CircularProgress />
    </Box>
  ) : isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;
