import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../redux/auth/authAction';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
  drawerWidth,
  drawerBackground,
  drawerTextColor,
  drawerActiveListBackgroundColor,
} from './UtilAminNavbar';

import { drawerMenuOptions } from './UtilAminNavbar';
import { hasChildren } from './UtilAminNavbar';

// active sidebar menu color

function capitalizeFirstLetter(str) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

function AdminNavbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: drawerBackground,
            color: drawerTextColor,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignItems: '',
            // backgroundColor: 'rgba(255, 255, 255, 0.04)',
            minHeight: 90,
            margin: 2,
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography
            noWrap
            variant="subtitle2"
            sx={{
              // width: '100%',
              // textAlign: 'center',
              color: (theme) => theme.palette.neutral[100],
            }}
          >
            {capitalizeFirstLetter(user?.name)}
          </Typography>
          <Typography
            noWrap
            variant="caption"
            sx={{
              // width: '100%',
              // textAlign: 'center',
              color: (theme) => theme.palette.neutral[100],
            }}
          >
            {/* Hello {capitalizeFirstLetter(user.firstName)} */}
          </Typography>
        </Box>

        <Divider sx={{ borderColor: '#2D3748' }} />
        {/** Drawer menu list goes here. See  */}

        <List
          sx={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          {drawerMenuOptions.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))}
        </List>

        <List
          sx={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <ListItem
            disableGutters
            sx={{
              mb: 0.5,
              py: 1,
              px: 3,
              borderRadius: 1,
              '&:hover': {
                backgroundColor: drawerActiveListBackgroundColor,
              },
            }}
            button
            onClick={() => {
              dispatch(logoutAction());
              navigate('/login');
            }}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                color: 'inherit',
                fontWeight: 'medium',
                variant: 'body2',
              }}
              primary="Logout"
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default AdminNavbar;

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ListItem
      disableGutters
      sx={{
        mb: 0.5,
        py: 1,
        px: 3,
        borderRadius: 1,
        ...(location.pathname === item.path && {
          backgroundColor: drawerActiveListBackgroundColor,
          color: 'secondary.main',
          fontWeight: 'medium',
        }),
        '&:hover': {
          backgroundColor: drawerActiveListBackgroundColor,
        },
      }}
      button
      onClick={() => {
        navigate(item.path);
      }}
    >
      <ListItemIcon fontSize="small" sx={{ color: 'inherit' }}>
        {item.icon}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          color: 'inherit',
          fontWeight: 'medium',
          variant: 'body2',
        }}
        primary={item.text}
      />
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  // const location = useLocation();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          mb: 0.5,
          py: 1,
          px: 3,
          borderRadius: 1,
          // write a conditonal to say that location.pathname matches one of the children's pathname
          // ...(open &&
          //   location.pathname === children && {
          //     backgroundColor: drawerActiveListBackgroundColor,
          //     color: 'secondary.main',
          //     fontWeight: 'medium',
          //   }),
          '&:hover': {
            backgroundColor: drawerActiveListBackgroundColor,
          },
        }}
        button
        onClick={handleClick}
      >
        <ListItemIcon fontSize="small" sx={{ color: 'currentColor' }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: 'inherit',
            fontWeight: 'medium',
            variant: 'body2',
          }}
          primary={item.text}
        />
        {open ? <ExpandMoreIcon /> : <NavigateNextIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
