import {
  LECTURE_DATA_LOADED,
  LECTURE_ERROR,
  LECTURE_ADDED,
} from './lectureTypes';
const initialState = {
  isLoading: true,
  isError: false,
  lectureData: [],
};

function lectureReducer(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case LECTURE_DATA_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        lectureData: payload,
      };

    case LECTURE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        lectureData: [],
      };

    case LECTURE_ADDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default lectureReducer;
