import { SHOW_SNACKBAR, CLOSE_SNACKBAR } from './alertTypes';

export const showSnackbar = (message, snackbarType) => {
  return (dispatch) => {
    dispatch({ type: SHOW_SNACKBAR, message, snackbarType });
  };
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_SNACKBAR });
  };
};
