import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
import { Card, CardHeader, Typography } from '@mui/material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import { loadFacultyDataAction } from '../../redux/Faculty/facultyAction';
import { Update } from '@mui/icons-material';

const StyledForm = styled('form')({
  width: '100%',
});

const DropzoneBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #ccc',
  borderRadius: 4,
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: '#f8f9fc',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const ImagePreview = styled('div')({
  position: 'relative',
  width: 150,
  height: 150,
  margin: 'auto',
  borderRadius: 8,
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  button: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    padding: 5,
    fontSize: 14,
  },
});

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

function AddFacultyForm({ editData, closeEditDialog }) {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      console.log({ file });
      file.preview = URL.createObjectURL(file); // Create a preview URL for the image
      setImage(file); // Set the single file as the selected image
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept only image files
    maxFiles: 1, // Allow only one file at a time
  });

  const initialValues = {
    name: editData?.name || '',
    designation: editData?.designation || '',
  };

  const removeImage = () => {
    if (image) {
      URL.revokeObjectURL(image.preview); // Clean up the preview URL
      setImage(null); // Remove the image
    }
  };

  const onSubmit = async (values, actions) => {
    const formDataObj = new FormData();

    if (image && !image.Location) {
      formDataObj.append('file', image); // Append the selected image
    }

    try {
      let imgRes = '';

      if (image && !image.Location) {
        imgRes = await api.post('fileUploads', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data', // Optional, Axios handles this automatically
          },
        });
      }

      let spkrData = {
        userDetails: {
          name: values?.name,
          designation: values?.designation,
        },
      };

      if (imgRes) {
        spkrData.userDetails.data = imgRes.data.data;
      }

      let response = '';

      if (editData) {
        response = await api.patch(`faculty/${editData?._id}`, spkrData);
        dispatch(loadFacultyDataAction(response.data.data.savedFaculties));
      } else {
        response = await api.post('faculty', spkrData);
        dispatch(loadFacultyDataAction(response.data.data.faculties));
      }
      closeEditDialog();
      actions.resetForm({
        name: '',
        designation: '',
      });
      removeImage(); // Clear the selected image after submission
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (editData) {
      setImage(editData?.image?.[0]);
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sm>
          <Card>
            <CardHeader
              title="Add Members to Pannel"
              titleTypographyProps={{
                fontSize: '24px', // Adjust the font size as needed
              }}
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2 }}>
              <StyledForm
                className="employee-form"
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid sx={{ marginBottom: 2 }} item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="name"
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid sx={{ marginBottom: 2 }} item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="designation"
                      multiline
                      name="designation"
                      label="Designation"
                      value={formik.values.designation}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.designation &&
                        Boolean(formik.errors.designation)
                      }
                      helperText={
                        formik.touched.designation && formik.errors.designation
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DropzoneBox {...getRootProps()}>
                      <input {...getInputProps()} />
                      {!image ? (
                        <p>
                          Drag & drop an image here, or click to select a file
                        </p>
                      ) : (
                        <ImagePreview>
                          <img
                            src={
                              image.preview ? image.preview : image?.Location
                            }
                            alt="Preview"
                          />
                          <button onClick={removeImage}>×</button>
                        </ImagePreview>
                      )}
                    </DropzoneBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <Button
                        sx={{ float: 'right' }}
                        type="submit"
                        startIcon={editData ? <Update /> : <AddIcon />}
                        color="primary"
                        variant="contained"
                        disabled={formik.isSubmitting}
                      >
                        {editData ? 'Update' : 'Add'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </StyledForm>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddFacultyForm;
