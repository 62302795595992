import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
import { useEffect, useState } from 'react';

const StyledForm = styled('form')({
  //   display: 'flex',
  //   alignItems: 'center',
  width: '100%',
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const validationSchema = yup.object().shape({
  appCode: yup.string().required('Required'),
  webCode: yup.string().required('Required'),
});

function AddCredForm() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const onSubmit = async (values, actions) => {
    const formData = {
      credDetails: {
        code: values.appCode,
        webCode: values.webCode,
      },
    };
    console.log({ formData });
    try {
      const response = await api.patch(`/auth/cred/${data?._id}`, formData);
      dispatch(showSnackbar(response.data.message, 'success'));

      setData(response.data.data.cred);
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const getCredData = async () => {
    try {
      const response = await api.get('/auth/creds');
      console.log({ response });
      setData(response.data.data.cred);
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };
  const initialValues = {
    appCode: data?.code || '',
    webCode: data?.webCode || '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    getCredData();
  }, []);

  return (
    <StyledForm
      className="employee-form"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <Grid container spacing={{ xs: 1 }}>
        <Grid item xs={12} md={5}>
          <TextField
            sx={{ marginRight: 2 }}
            size="small"
            fullWidth
            id="countryCode"
            name="appCode"
            label="App Code"
            value={formik.values.appCode}
            onChange={formik.handleChange}
            error={formik.touched.appCode && Boolean(formik.errors.appCode)}
            helperText={formik.touched.appCode && formik.errors.appCode}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            sx={{ marginRight: 2 }}
            size="small"
            fullWidth
            id="mobile"
            multiline
            name="webCode"
            label="Web Code"
            value={formik.values.webCode}
            onChange={formik.handleChange}
            error={formik.touched.webCode && Boolean(formik.errors.webCode)}
            helperText={formik.touched.webCode && formik.errors.webCode}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box>
            <Button
              sx={{ float: 'right' }}
              type="submit"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              Update
            </Button>
          </Box>
        </Grid>
      </Grid>
    </StyledForm>
  );
}

export default AddCredForm;
