import Container from '@mui/material/Container';
import AccessControlTable from '../../components/venue/VenueTable';
import PageHeading from '../../components/reusables/PageHeading';

function AddVenuePage() {
  return (
    <Container maxWidth="lg">
      <PageHeading />
      <AccessControlTable />
    </Container>
  );
}

export default AddVenuePage;
