import { useSelector, useDispatch } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { clearSnackbar } from '../../redux/alert/alertAction';

export const SnackbarComp = () => {
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearSnackbar());
  };

  return (
    <Snackbar
      open={snackbarOpen}
      onClose={handleClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        severity={snackbarType}
        onClose={() => handleClose()}
        sx={{ width: 500 }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};
