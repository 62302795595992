import {
  LECTURE_DATA_LOADED,
  LECTURE_ERROR,
  LECTURE_ADDED,
} from './lectureTypes';

export const loadLectureDataAction = (data) => (dispatch) => {
  dispatch({
    type: LECTURE_DATA_LOADED,
    payload: data,
  });
};

export const lectureErrorAction = () => (dispatch) => {
  dispatch({
    type: LECTURE_ERROR,
  });
};

export const lectureAddedAction = () => (dispatch) => {
  dispatch({
    tyep: LECTURE_ADDED,
  });
};
