import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
// import { getAccessControlData } from './UtilAccessControl';
import Paper from '@mui/material/Paper';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
import DialogAlert from '../alert/DialogueAlert';
import { loadFacultyDataAction } from '../../redux/Faculty/facultyAction';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { loadNotificationDataAction } from '../../redux/notification/notificationAction';
import BroadcastNotification from './BroadcastNotification';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
//  for formik

function NotificationTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState('');

  // delete user

  const deleteVenue = async () => {
    try {
      const response = await api.delete(
        `notifications/dashboard/${rowToDelete?._id}`
      );
      dispatch(loadNotificationDataAction(response.data.data.notifications));
      closeDeleteDialog();
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  const openDeleteDialog = useCallback(
    (id, row) => () => {
      console.log({ row });
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );
  const { notificationData } = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const getNotifications = async () => {
    try {
      const response = await api.get('/notifications');
      console.log({ response });
      dispatch(loadNotificationDataAction(response.data.data.notifications));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit(null);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      description: 'Wiroc ID',
      type: 'string',
      minWidth: 150,
      flex: 1,
    },

    {
      field: 'title',
      headerName: 'Title',
      description: 'title',
      type: 'string',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'body',
      headerName: 'Message',
      description: 'Message',
      type: 'string',
      minWidth: 300,

      flex: 1,
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       whiteSpace: 'normal',
      //       wordWrap: 'break-word',
      //       lineHeight: '1.5',
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      description: 'Modify table',
      type: 'actions',
      minWidth: 200,
      flex: 1,
      getActions: (params) => {
        // console.log(params.row);
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={openDeleteDialog(params.id, params.row)}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    getNotifications();
  }, []);
  console.log({ notificationData });
  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" component="h4">
          Broadcast Message
        </Typography>

        {/* Subheading */}
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'gray', marginBottom: 2 }}
        >
          Broadcast a message for users using +Add button below.
        </Typography>

        <Button
          type="button"
          startIcon={<AddIcon />}
          color="primary"
          variant="contained"
          onClick={() => setisEditDialogOpen(true)}
        >
          Add
        </Button>
      </Box>
      <Paper
        sx={{
          height: 600,
          width: '100%',
          marginTop: 2,
          // boxShadow: 8,
          padding: 4,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          rows={notificationData}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              lineHeight: '1.5',
              paddingTop: '8px',
              paddingBottom: '8px',
            },
          }}
        />
        <Modal
          open={isEditDialogOpen}
          // onClose={closeEditDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* Cross Button */}
            <IconButton
              aria-label="close"
              onClick={closeEditDialog}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>

            {/* Content Inside Modal */}
            <BroadcastNotification
              closeEditDialog={closeEditDialog}
              editData={rowToEdit}
            />
          </Box>
        </Modal>
        <DialogAlert
          open={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          handleYes={deleteVenue}
          title={`Delete notification ${rowToDelete?.title}`}
          msg={
            deleteErr ||
            `Are you sure you want to delete announcement: ${rowToDelete?.title}?`
          }
        />
      </Paper>
    </>
  );
}

export default NotificationTable;
