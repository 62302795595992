import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
import { Card, CardHeader, Modal } from '@mui/material';

const StyledForm = styled('form')({
  //   display: 'flex',
  //   alignItems: 'center',
  width: '100%',
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

function AddVenueForm({ editData, closeEditDialog }) {
  const initialValues = {
    name: editData?.name || '',
    // address: editData?.address || '',
  };

  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      venueDetails: {
        name: values.name,
        // address: values.address,
      },
    };
    try {
      let response = '';
      if (editData) {
        response = await api.patch(`venue/${editData?._id}`, formData);
      } else {
        response = await api.post(`venue`, formData);
      }

      dispatch(loadVenueDataAction(response.data.data.venues));
      closeEditDialog();
      actions.resetForm({
        name: '',
        // address: '',
      });
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sm>
          <Card>
            <CardHeader
              title="Add a hall"
              titleTypographyProps={{
                fontSize: '24px', // Adjust the font size as needed
              }}
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2 }}>
              <StyledForm
                className="employee-form"
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid sx={{ marginBottom: 2 }} item xs={12} md={12}>
                    <TextField
                      sx={{ marginRight: 2 }}
                      size="small"
                      fullWidth
                      id="countryCode"
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  {/* <Grid sx={{ marginBottom: 2 }} item xs={12} md={12}>
                    <TextField
                      sx={{ marginRight: 2 }}
                      size="small"
                      fullWidth
                      id="mobile"
                      multiline
                      name="address"
                      label="Address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid> */}

                  <Grid item xs={12} md={12}>
                    <Box>
                      <Button
                        sx={{ float: 'right' }}
                        type="submit"
                        startIcon={<AddIcon />}
                        color="primary"
                        variant="contained"
                        disabled={formik.isSubmitting}
                      >
                        {editData ? 'Update' : 'Add'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </StyledForm>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddVenueForm;
