import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import api from '../utilities/api';
import { showSnackbar } from '../redux/alert/alertAction';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Card,
  CardHeader,
  IconButton,
  Modal,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { loadSessionDataAction } from '../redux/session/sessionAction';
import dayjs from 'dayjs';
import { Close, Update } from '@mui/icons-material';
import { loadLectureDataAction } from '../redux/lecture/lectureAction';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { loadFacultyDataAction } from '../redux/Faculty/facultyAction';
import AddFacultyForm from '../components/faculty/AddFaculty';

const StyledForm = styled('form')({
  //   display: 'flex',
  //   alignItems: 'center',
  width: '100%',
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validationSchema = yup.object().shape({
  title: yup.string().required('Required'),
  speakers: yup
    .array()
    .of(yup.string().required('Invalid faculty selected')) // Each selected value should be a string
    .min(1, 'At least one speaker must be selected') // At least one item must be selected
    .required('Faculty required'),
  endDateTime: yup
    .date()
    .required('End date and time is required')
    .typeError('Invalid date')
    .min(new Date(), 'End date and time must be in the future'),
  startDateTime: yup
    .date()
    .required('End date and time is required')
    .typeError('Invalid date')
    .min(new Date(), 'End date and time must be in the future'),
});

function AddLectureForm({ closeEditDialog, editData }) {
  const dispatch = useDispatch();

  const { lectureData } = useSelector((state) => state.lecture);

  let spekrs = editData?.speakers.map((spk) => spk?._id);
  const [isSpkrConvrDialogueOpen, setIsSpkrConvrDialogueOpen] = useState(false);

  const initialValues = useMemo(
    () => ({
      fixedDate: dayjs(lectureData?.startDate),
      fixedEndDate: dayjs(lectureData?.endDate),
      sessionTitle: lectureData?.title,
      title: editData?.title || '',
      startDateTime: editData?.startDate ? dayjs(editData.startDate) : null,
      endDateTime: editData?.endDate ? dayjs(editData.endDate) : null,
      speakers: spekrs || [],
    }),
    [editData, spekrs, lectureData]
  );
  const onSubmit = async (values, actions) => {
    const formData = {
      episodeDetails: {
        title: values.title,
        startDate: values.startDateTime,
        endDate: values.endDateTime,
        speakers: values?.speakers,
        sessionId: lectureData?._id,
      },
    };
    try {
      let response = '';

      if (editData) {
        formData.episodeDetails.episodeId = editData?._id;
        response = await api.patch(`/event/episodes`, formData);
      } else {
        response = await api.post(`/event/episodes`, formData);
      }
      closeEditDialog();
      dispatch(loadLectureDataAction(response.data.data.session));
      actions.resetForm({});
      dispatch(showSnackbar(response.data.message, 'success'));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };
  const { facultyData } = useSelector((state) => state.faculty);
  const getAllDropdownData = async () => {
    try {
      const response = await api.get('/event/bd/data');

      dispatch(loadFacultyDataAction(response.data.data.speakers));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const closeSpkrConvrDialog = () => {
    setIsSpkrConvrDialogueOpen(false);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    getAllDropdownData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sm>
          <Card>
            <CardHeader
              title="Add a lecture"
              titleTypographyProps={{
                fontSize: '24px', // Adjust the font size as needed
              }}
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2 }}>
              <StyledForm
                className="employee-form"
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Grid sx={{ marginBottom: 2 }} container spacing={{ xs: 2 }}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      sx={{ marginRight: 2, marginBottom: 2 }}
                      size="large"
                      fullWidth
                      disabled
                      id="title"
                      name="sessionTitle"
                      label="Session"
                      value={formik.values.sessionTitle}
                    />
                  </Grid>
                  <Grid sx={{ marginBottom: 2 }} item xs={12} md={12}>
                    <TextField
                      sx={{ marginRight: 2 }}
                      size="large"
                      fullWidth
                      id="title"
                      name="title"
                      label="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl
                      fullWidth
                      size="large"
                      error={
                        formik.touched.speakers &&
                        Boolean(formik.errors.speakers)
                      }
                    >
                      <InputLabel id="userType-label">Speakers</InputLabel>
                      <Select
                        labelId="userType-label"
                        id="userType"
                        multiple
                        name="speakers"
                        value={formik.values.speakers}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {facultyData?.map((spkr) => (
                          <MenuItem key={spkr?._id} value={spkr?._id}>
                            {spkr?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.speakers && formik.errors.speakers && (
                        <FormHelperText>
                          {formik.errors.speakers}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      onClick={() => setIsSpkrConvrDialogueOpen(true)}
                      sx={{ float: 'right' }}
                      variant="text"
                    >
                      Add a new speaker
                    </Button>
                  </Grid>

                  <Grid sx={{ mb: 2 }} item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopTimePicker
                        name="startDateTime"
                        label="Start Time"
                        minutesStep={1} // Increment time by 1 minute
                        value={formik.values.startDateTime}
                        onChange={(newValue) => {
                          if (newValue) {
                            // Combine fixed date with selected time
                            const updatedValue = formik.values.fixedDate
                              .hour(newValue.hour())
                              .minute(newValue.minute())
                              .second(newValue.second());
                            formik.setFieldValue('startDateTime', updatedValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            name="startDateTime"
                            error={
                              formik.touched.startDateTime &&
                              Boolean(formik.errors.startDateTime)
                            }
                            helperText={
                              formik.touched.startDateTime &&
                              formik.errors.startDateTime
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopTimePicker
                        name="endDateTime"
                        label="End Time"
                        minutesStep={1} // Increment time by 1 minute
                        value={formik.values.endDateTime}
                        onChange={(newValue) => {
                          if (newValue) {
                            // Combine fixed date with selected time
                            const updatedValue = formik.values.fixedDate
                              .hour(newValue.hour())
                              .minute(newValue.minute())
                              .second(newValue.second());
                            formik.setFieldValue('endDateTime', updatedValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            name="endDateTime"
                            error={
                              formik.touched.endDateTime &&
                              Boolean(formik.errors.endDateTime)
                            }
                            helperText={
                              formik.touched.endDateTime &&
                              formik.errors.endDateTime
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box>
                      <Button
                        sx={{ float: 'right' }}
                        type="submit"
                        startIcon={editData ? <Update /> : <AddIcon />}
                        color="primary"
                        variant="contained"
                        disabled={formik.isSubmitting}
                      >
                        {editData ? 'Update' : 'Add'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </StyledForm>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={isSpkrConvrDialogueOpen}
        // onClose={closeEditDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* Cross Button */}
          <IconButton
            aria-label="close"
            onClick={closeSpkrConvrDialog}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          {/* Content Inside Modal */}
          <AddFacultyForm
            closeEditDialog={closeSpkrConvrDialog}
            editData={null}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default AddLectureForm;
