import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function PageHeading({ heading, action }) {
  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'ceneter',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h4">{heading}</Typography>
      </Box>
      <Box>{action}</Box>
    </Box>
  );
}

export default PageHeading;
