import React from 'react';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page
  };

  return (
    <Button
      variant="outlined"
      startIcon={<ArrowBackIcon />}
      onClick={handleBack}
      data-testid="back-button" // For testing purposes
      sx={{
        marginTop: 2,
        textTransform: 'none', // Optional: Keeps the button text in its original case
      }}
    >
      Go Back
    </Button>
  );
};

export default BackButton;
