import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import snackbarReducer from './alert/alertReducer';
import venueReducer from './venue/venueReducer';
import facultyReducer from './Faculty/facultyReducer';
import sessionReducer from './session/sessionReducer';
import lectureReducer from './lecture/lectureReducer';
import notificationReducer from './notification/notificationReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  venue: venueReducer,
  faculty: facultyReducer,
  session: sessionReducer,
  lecture: lectureReducer,
  notification: notificationReducer,
});

export default rootReducer;
