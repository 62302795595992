import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import Cred from '../../components/cred/Cred';

function CredPage() {
  return (
    <Container maxWidth="lg">
      <PageHeading heading="Security Codes" />
      <Cred />
    </Container>
  );
}

export default CredPage;
