import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import AddSessionToolbar from '../../components/session/AddSessionToolbar';
import SessionTable from '../../components/session/SessionTable';

function AddSessionPage() {
  return (
    <Container maxWidth="lg">
      <PageHeading />
      {/* <AddSessionToolbar /> */}
      <SessionTable />
    </Container>
  );
}

export default AddSessionPage;
