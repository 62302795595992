import {
  FACULTY_DATA_LOADED,
  FACULTY_ERROR,
  FACULTY_ADDED,
} from './facultyTypes';

export const loadFacultyDataAction = (data) => (dispatch) => {
  dispatch({
    type: FACULTY_DATA_LOADED,
    payload: data,
  });
};

export const facultyErrorAction = () => (dispatch) => {
  dispatch({
    type: FACULTY_ERROR,
  });
};

export const facultyAddedAction = () => (dispatch) => {
  dispatch({
    tyep: FACULTY_ADDED,
  });
};
