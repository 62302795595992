import { Fragment, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { loadUserAction } from './redux/auth/authAction';
import setAuthToken from './utilities/setAuthToken';
import LoginPage from './pages/login-page/LoginPage';
import SignupPage from './pages/signup-page/SignupPage';
import { SnackbarComp } from './components/snackbar/Snackbar';
import DashboardLayoutPage from './pages/layout-pages/DashboardLayoutPage';
import PrivateRoute from './components/routing/PrivateRoute';
import AddVenuePage from './pages/add-venue/AddVenuePage';
import CredPage from './pages/cred-page/CredPage';
import FacultyPage from './pages/faculty-pages/FacultyPage';
import AddSessionPage from './pages/session-pages/SessionPage';
import LecturePage from './pages/lecture-page/LecturePage';
import WelcomePage from './pages/welcome-page/WelcomePage';
import NotificationPage from './pages/notification/NotificationPage';
import FeedbackPage from './pages/feedback-page/FeedbackPage';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setAuthToken(localStorage.token);
    dispatch(loadUserAction());
  }, []);

  return (
    <Fragment>
      <BrowserRouter>
        <SnackbarComp />
        <Routes>
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/signup" element={<SignupPage />}></Route>
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <DashboardLayoutPage />
              </PrivateRoute>
            }
          >
            <Route path="venue" element={<AddVenuePage />}></Route>
            <Route path="cred" element={<CredPage />}></Route>
            <Route path="faculty" element={<FacultyPage />}></Route>
            <Route path="notifications" element={<NotificationPage />}></Route>
            <Route path="feedbacks" element={<FeedbackPage />}></Route>
            <Route path="" element={<AddSessionPage />}></Route>
            <Route path="lecture/:lectureId" element={<LecturePage />}></Route>
            {/* <Route path="" element={<WelcomePage />}></Route> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  user: state.auth.user,
});

export default connect(mapStateToProps)(App);
