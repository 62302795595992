import { SHOW_SNACKBAR, CLOSE_SNACKBAR } from './alertTypes';
const initialState = {
  snackbarOpen: false,
  snackbarType: '',
  snackbarMessage: '',
};

function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: action.snackbarType,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarMessage: '',
      };
    default:
      return state;
  }
}
export default snackbarReducer;
