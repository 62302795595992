import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import AddLectureToolbar from '../../lectures/AddLectureToolbar';
import LectureTable from '../../lectures/LectureTable';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utilities/api';
import { loadLectureDataAction } from '../../redux/lecture/lectureAction';
import { showSnackbar } from '../../redux/alert/alertAction';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../../components/buttons/BackButton';

function LecturePage() {
  const dispatch = useDispatch();
  const lectureId = useParams().lectureId;

  const getLectureData = async () => {
    try {
      const response = await api.get(`/event/sessions/${lectureId}`);

      dispatch(loadLectureDataAction(response.data.data.session));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    getLectureData();
    return () => {
      dispatch(loadLectureDataAction({}));
    };
  }, []);

  return (
    <Container maxWidth="lg">
      <PageHeading />
      <BackButton />
      <LectureTable />
    </Container>
  );
}

export default LecturePage;
