import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AdminAppBar from '../../components/navbar/AdminAppBar';
import AdminDrawer from '../../components/navbar/AdminDrawer';
import Divider from '@mui/material/Divider';

export default function DashboardLayoutPage() {
  return (
    <Box sx={{ display: 'flex' }}>
      {/** AdminAppbar has fixed position to the top 0 and right 0 and left auto */}
      <AdminAppBar />
      <AdminDrawer />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
        {/** toolbar here gives empty height and content down bdelow appbar  */}
        <Toolbar />
        <Divider />
        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
