import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
// import { getAccessControlData } from './UtilAccessControl';
import Paper from '@mui/material/Paper';
import api from '../utilities/api';
import { showSnackbar } from '../redux/alert/alertAction';
import DialogAlert from '../components/alert/DialogueAlert';
import AddLectureForm from './AddLectureForm';
import { loadLectureDataAction } from '../redux/lecture/lectureAction';
import dayjs from 'dayjs';
import { Button, IconButton, Typography } from '@mui/material';
import { Add, Close } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

function LectureTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [deleteErr, setdeleteErr] = useState('');
  const dispatch = useDispatch();
  // delete user

  const deleteEpisode = async () => {
    try {
      const response = await api.delete(
        `event/sessions/${lectureData?._id}/episodes/${rowToDelete?._id}`
      );
      dispatch(loadLectureDataAction(response.data.data.session));
      closeDeleteDialog();
      dispatch(showSnackbar(response.data.message, 'success'));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );
  const { lectureData } = useSelector((state) => state.lecture);

  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit(null);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        description: 'Wiroc ID',
        type: 'string',
        minWidth: 50,
        flex: 1,
      },

      {
        field: 'title',
        headerName: 'Title',
        description: 'name',
        type: 'string',
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'startDate',
        headerName: 'Start Date & Time',
        description: 'Session start time',
        type: 'string',
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.startDate, // Get raw value from the row
        renderCell: (params) =>
          params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm') : '-', // Format and display
      },
      {
        field: 'endDate',
        headerName: 'End Date & Time',
        description: 'Session end time',
        type: 'string',
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.endDate, // Get raw value from the row
        renderCell: (params) =>
          params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm') : '-', // Format and display
      },

      {
        field: 'speakers',
        headerName: 'Speakers',
        description: 'Speakers for the session',
        type: 'string',
        minWidth: 150,
        flex: 1,
        valueGetter: (params) =>
          params.row.speakers?.map((speaker) => speaker.name).join(', ') || '-', // Join names with a comma
        renderCell: (params) =>
          params.value ? (
            <span>{params.value}</span>
          ) : (
            <span style={{ color: 'gray' }}>No Speakers</span>
          ),
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 200,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [deleteEpisode, editRow]
  );

  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" component="h4">
          Add Lecture
        </Typography>
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'gray', marginBottom: 2 }}
        >
          Add a Lecture using +Add button below.
        </Typography>

        {/* Subheading */}

        <Button
          sx={{ margingTop: 2 }}
          type="button"
          startIcon={<Add />}
          color="primary"
          variant="contained"
          onClick={() => setisEditDialogOpen(true)}
        >
          Add
        </Button>
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ marginBottom: 1, marginTop: 4 }}
        >
          {`SESSION: ${lectureData?.title}`}
        </Typography>
      </Box>
      <Paper
        sx={{
          height: 600,
          width: '100%',
          marginTop: 2,
          // boxShadow: 8,
          padding: 4,
        }}
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light gray hover effect
              },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          rows={lectureData?.episodes}
          onRowClick={(r) => {
            setRowToEdit(r.row);
            setisEditDialogOpen(true);
          }}
        />

        <DialogAlert
          open={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          handleYes={deleteEpisode}
          title={`Delete episode ${rowToDelete?.title}`}
          msg={
            deleteErr ||
            `Are you sure you want to delete episode: ${rowToDelete?.title}?`
          }
        />
      </Paper>
      <Modal
        open={isEditDialogOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={closeEditDialog}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <AddLectureForm
            closeEditDialog={closeEditDialog}
            editData={rowToEdit}
          />
        </Box>
      </Modal>
    </>
  );
}

export default LectureTable;
