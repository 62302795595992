import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Box, display, styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';

import api from '../../utilities/api';

import { Typography, Link } from '@mui/material';
import { showSnackbar } from '../../redux/alert/alertAction';
import { useNavigate } from 'react-router-dom';
// import { loadUserAction } from '../../redux/auth/authAction';

const StyledForm = styled('form')({
  justifyContent: 'space-between',
});

const validation = yup.object({
  name: yup.string().required('Required'),
  email: yup
    .string()
    .email('Please provide a valid email')
    .required('Required'),
  securityCode: yup.string().required('Required'),
});

export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values, actions) => {
    const formData = {
      userDetails: {
        name: values.name,
        email: values.email,
        securityCode: values.securityCode,
      },
    };

    try {
      const response = await api.post(`/register/web`, formData);

      dispatch(showSnackbar(response.data.message, 'success'));
      navigate('/login');
    } catch (error) {
      dispatch(showSnackbar(error.response.data.message, 'error'));
    }
  };

  const initialValues = {
    name: '',
    email: '',
    securityCode: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        marginTop: 7,
      }}
    >
      <Card sx={{ marginTop: 2 }}>
        <CardHeader title="Singup" />
        <CardContent>
          <StyledForm
            className="profile"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Grid container spacing={{ xs: 3 }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  // sx={{ input: { color: "text.disabled" } }}
                  size="small"
                  fullWidth
                  id="firstName"
                  name="name"
                  label="Name"
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  // sx={{ input: { color: "text.disabled" } }}
                  size="small"
                  fullWidth
                  id="lastName"
                  name="email"
                  label="Email"
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="mobile"
                  name="securityCode"
                  label="Security Code"
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  value={formik.values.securityCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.securityCode &&
                    Boolean(formik.errors.securityCode)
                  }
                  helperText={
                    formik.touched.securityCode && formik.errors.securityCode
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={formik.isSubmitting}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  // sx={{ float: 'right' }}
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  disabled={formik.isSubmitting}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: 5,
                textAlign: 'center',
                color: 'primary',
              }}
            >
              <Box>
                <Typography component="span" variant="body2">
                  Already have an account?
                </Typography>
                <Link
                  href="/login"
                  m={1}
                  color="primary"
                  variant="body2"
                  underline="hover"
                >
                  Login
                </Link>
              </Box>
            </Box>
          </StyledForm>
        </CardContent>
      </Card>
    </Box>
  );
}
