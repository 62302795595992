import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const BoxElement = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: 19,
  '.MuiTextField-root': { marginTop: '0' },
}));

function MobileEmail(props) {
  const { formik } = props;

  return (
    <BoxElement>
      <TextField
        sx={{
          transitionProperty: 'width',
          transitionDuration: '4s',
          transitionDelay: '2s',
        }}
        autoComplete="off"
        // size="small"
        fullWidth
        id="me"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
    </BoxElement>
  );
}

export default MobileEmail;
