import axios from 'axios';
import store from '../redux/store';
import { LOGOUT } from '../redux/auth/authTypes';

const api = axios.create({
  baseURL: 'https://wirocapi.dailydoc.io/api',
  // baseURL: 'http://localhost:3020/api',
  // baseURL:
  //   "https://ff05-2405-201-a415-116f-2dc6-64ec-976a-ef55.in.ngrok.io/api",
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'any',
  },
});

/**
 interceptor funciton is run async by default after then and cath.
 following interceptor function runs after a response is received. 
 there is a seperate interceptor function if one wants do something before making a request. 
 in the folliwing function, if one gets a response, we will return the response.
 if one gets an error, we will.
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired
 logout the user if the token has expired
**/

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.data.msg === 'Token is not valid') {
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);

api.interceptors.request.use(
  async (config) => {
    try {
      // Get the token from AsyncStorage
      const token = localStorage.getItem('token');

      if (token) {
        // Attach the token to the Authorization header
        config.headers['x-access-token'] = token;
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
    }
    return config;
  },
  (error) => {
    // Handle errors during request setup
    return Promise.reject(error);
  }
);

export default api;
