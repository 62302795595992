import {
  Event,
  People,
  Place,
  Security,
  Notifications,
  Feedback,
} from '@mui/icons-material';

// set side drawer width
export const drawerWidth = 250;
// set drawer background color
export const drawerBackground = 'neutral.900';
export const drawerTextColor = 'neutral.300';
export const drawerActiveListBackgroundColor = 'rgba(255,255,255, 0.08)';

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

// set drawer menu options
// set drawer submenu options and their paths

export const drawerMenuOptions = [
  // { text: 'Dasbboard', icon: <DashboardIcon />, path: '/dashboard', items: [] },
  {
    text: 'Add Schedule',
    icon: <Event />,
    path: '/dashboard',
    items: [],
  },
  {
    text: 'Add User',
    icon: <People />,
    path: '/dashboard/faculty',
    items: [],
  },
  {
    text: 'Add hall',
    icon: <Place />,
    path: '/dashboard/venue',
    items: [],
  },
  {
    text: 'Announcements',
    icon: <Notifications />,
    path: '/dashboard/notifications',
    items: [],
  },
  {
    text: 'Feedbacks',
    icon: <Feedback />,
    path: '/dashboard/feedbacks',
    items: [],
  },

  {
    text: 'Security Code',
    icon: <Security />,
    path: '/dashboard/cred',
    items: [],
  },
];
