import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';
import rootReducer from './rootReducer';
import setAuthToken from '../utilities/setAuthToken';

const persistConfig = {
  key: 'root',
  storage,
};
const middleware = [thunk];

const pReducer = persistReducer(persistConfig, rootReducer);

// if (process.env.NODE_ENV === "development") {
//   middlewares.push(logger);
// }

export const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

// set up a store subscription listener*****
//to store the users token in localStorage****

//initialize current state from redux store for subscription comparison****
//preventing undefined error*****
let currentState = store.getState();

store.subscribe(() => {
  // keep track of the previous and current state to compare changes*****
  let previousState = currentState;
  currentState = store.getState();
  // if the token changes set the value in localStorage and axios headers*******
  if (previousState.auth.token !== currentState.auth.token) {
    const token = currentState.auth.token;
    setAuthToken(token);
  }
});

export default store;
