import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
// import { getAccessControlData } from './UtilAccessControl';
import Paper from '@mui/material/Paper';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
import DialogAlert from '../alert/DialogueAlert';
import { loadFacultyDataAction } from '../../redux/Faculty/facultyAction';
import AddFacultyForm from './AddFaculty';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
//  for formik

const validationSchema = yup.object().shape({
  name: yup.string('').required('Required'),
  address: yup.string().required('Required'),
});

function FacultyTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [deleteErr, setdeleteErr] = useState('');

  // for formik set up
  const onFormSubmit = async (values, actions) => {
    const formData = {
      venueDetails: {
        name: values.name,
        address: values.address,
      },
    };
    try {
      const response = await api.patch(`venue/${rowToEdit?._id}`, formData);

      dispatch(loadVenueDataAction(response.data.data.venues));
      closeEditDialog();
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: rowToEdit ? rowToEdit?.name : '',
      address: rowToEdit ? rowToEdit?.address : '',
    },
    validationSchema: validationSchema,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
  });

  // delete user

  const deleteVenue = async () => {
    try {
      const response = await api.delete(`faculty/${rowToDelete?._id}`);
      dispatch(loadFacultyDataAction(response.data.data.faculties));
      closeDeleteDialog();
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  const openDeleteDialog = useCallback(
    (id, row) => () => {
      console.log({ row });
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );
  const { facultyData } = useSelector((state) => state.faculty);

  const dispatch = useDispatch();
  const getVenueData = async () => {
    try {
      const response = await api.get('/faculty');

      dispatch(loadFacultyDataAction(response.data.data.faculties));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit(null);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        description: 'Wiroc ID',
        type: 'string',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'data',
        headerName: 'Image',
        description: 'Venue Image',
        type: 'string',
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <img
            src={params.row.image[0]?.Location} // Assuming 'image' contains the image URL
            alt={params.row.name}
            style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              objectFit: 'cover',
              border: '1px solid #ccc',
            }}
          />
        ),
      },
      {
        field: 'name',
        headerName: 'Name',
        description: 'name',
        type: 'string',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'designation',
        headerName: 'Designation',
        description: 'Address',
        type: 'string',
        minWidth: 150,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 200,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [deleteVenue, editRow]
  );

  useEffect(() => {
    getVenueData();
    return () => {};
  }, []);

  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" component="h4">
          Add User
        </Typography>

        {/* Subheading */}
        <Typography variant="subtitle1" component="h2" sx={{ color: 'gray' }}>
          Add a speaker or convenor or organizer or person or committee members
          using +Add button below.
        </Typography>
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'gray', marginBottom: 2 }}
        >
          This will create a database of speakers, panelists, organizing members
          etc to be assigned in other sections.
        </Typography>

        <Button
          type="button"
          startIcon={<AddIcon />}
          color="primary"
          variant="contained"
          onClick={() => setisEditDialogOpen(true)}
        >
          Add
        </Button>
      </Box>
      <Paper
        sx={{
          height: 600,
          width: '100%',
          marginTop: 2,
          // boxShadow: 8,
          padding: 4,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          rows={facultyData}
          onRowClick={(params) => {
            setRowToEdit(params.row);
            setisEditDialogOpen(true);
          }}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light gray hover effect
              },
            },
          }}
        />
        <Modal
          open={isEditDialogOpen}
          // onClose={closeEditDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* Cross Button */}
            <IconButton
              aria-label="close"
              onClick={closeEditDialog}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>

            {/* Content Inside Modal */}
            <AddFacultyForm
              closeEditDialog={closeEditDialog}
              editData={rowToEdit}
            />
          </Box>
        </Modal>
        <DialogAlert
          open={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          handleYes={deleteVenue}
          title={`Delete faculty ${rowToDelete?.name}`}
          msg={
            deleteErr ||
            `Are you sure you want to delete faculty: ${rowToDelete?.name}?`
          }
        />
      </Paper>
    </>
  );
}

export default FacultyTable;
