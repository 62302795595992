import { VENUE_DATA_LOADED, VENUE_ERROR, VENUE_ADDED } from './venueTypes';
const initialState = {
  isLoading: true,
  isError: false,
  accessControlData: [],
};

function venueReducer(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case VENUE_DATA_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        venueData: payload,
      };

    case VENUE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        venueData: [],
      };

    case VENUE_ADDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default venueReducer;
