import Container from '@mui/material/Container';
import PageHeading from '../../components/reusables/PageHeading';
import AddFacultyForm from '../../components/faculty/AddFaculty';
import FacultyTable from '../../components/faculty/FacultyTable';

function FacultyPage() {
  return (
    <Container maxWidth="lg">
      <PageHeading />
      <FacultyTable />
    </Container>
  );
}

export default FacultyPage;
