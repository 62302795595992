import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
import { Card, CardHeader, Modal } from '@mui/material';
import { loadNotificationDataAction } from '../../redux/notification/notificationAction';

const StyledForm = styled('form')({
  //   display: 'flex',
  //   alignItems: 'center',
  width: '100%',
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const validationSchema = yup.object().shape({
  title: yup.string().trim().required('Required'),
  message: yup.string().trim().required('Required'),
});

function BroadcastNotification({ editData, closeEditDialog }) {
  const initialValues = {
    title: '',
    message: '',
  };

  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      title: values.title,
      body: values?.message,
    };
    try {
      let response = await api.post('/notifications/send', formData);

      dispatch(loadNotificationDataAction(response.data.data.announcements));
      closeEditDialog();
      actions.resetForm({
        title: '',
        // address: '',
      });
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sm>
          <Card>
            <CardHeader
              title="Broadcast a message"
              titleTypographyProps={{
                fontSize: '24px', // Adjust the font size as needed
              }}
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2 }}>
              <StyledForm
                className="employee-form"
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid sx={{ marginBottom: 2 }} item xs={12} md={12}>
                    <TextField
                      sx={{ marginRight: 2 }}
                      size="small"
                      fullWidth
                      id="countryCode"
                      name="title"
                      label="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>
                  <Grid sx={{ marginBottom: 2 }} item xs={12} md={12}>
                    <TextField
                      sx={{ marginRight: 2 }}
                      size="small"
                      fullWidth
                      id="mobile"
                      multiline
                      name="message"
                      label="Message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.message && Boolean(formik.errors.message)
                      }
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box>
                      <Button
                        sx={{ float: 'right' }}
                        type="submit"
                        startIcon={<AddIcon />}
                        color="primary"
                        variant="contained"
                        disabled={formik.isSubmitting}
                      >
                        Add
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </StyledForm>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BroadcastNotification;
