import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Card,
  CardHeader,
  Modal,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { loadSessionDataAction } from '../../redux/session/sessionAction';
import dayjs from 'dayjs';
import { Close, Update } from '@mui/icons-material';
import AddVenueForm from '../venue/AddVenueForm';
import AddFacultyForm from '../faculty/AddFaculty';
import { loadFacultyDataAction } from '../../redux/Faculty/facultyAction';

const StyledForm = styled('form')({
  //   display: 'flex',
  //   alignItems: 'center',
  width: '100%',
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validationSchema = yup.object().shape({
  title: yup.string().trim().required('Required'),
  venue: yup.string().required('Required'),
  endDateTime: yup
    .date()
    .nullable()
    .required('Required')
    .typeError('Invalid date')
    .min(new Date(), 'End date and time must be in the future'),

  startDateTime: yup
    .date()
    .nullable()
    .required('Required')
    .typeError('Invalid date format')
    .min(new Date(), 'Start date and time must be in the future'),
});

function AddSessionForm({ closeEditDialog, editData }) {
  const dispatch = useDispatch();

  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isSpkrConvrDialogueOpen, setIsSpkrConvrDialogueOpen] = useState(false);
  const { venueData } = useSelector((state) => state.venue);
  let conv = editData?.convenors.map((conv) => conv?._id);
  let spekrs = editData?.speakers.map((spk) => spk?._id);
  const { facultyData } = useSelector((state) => state.faculty);
  const initialValues = useMemo(
    () => ({
      title: editData?.title || '',
      topic: editData?.topic || '',
      venue: editData?.venue?._id || '',
      startDateTime: editData?.startDate ? dayjs(editData.startDate) : null,
      endDateTime: editData?.endDate ? dayjs(editData.endDate) : null,
      convenors: conv || [],
      speakers: spekrs || [],
    }),
    [editData, conv, spekrs]
  );
  const onSubmit = async (values, actions) => {
    const formData = {
      sessionDetails: {
        title: values.title,
        topic: values.topic,
        startDate: values.startDateTime,
        endDate: values.endDateTime,
        convenors: values?.convenors,
        speakers: values?.speakers,
        venue: values?.venue,
      },
    };
    try {
      let response = '';

      if (editData) {
        formData.sessionDetails.sessionId = editData?._id;
        response = await api.post(`/event/sessions`, formData);
      } else {
        response = await api.post(`/event/sessions`, formData);
      }
      closeEditDialog();
      dispatch(loadSessionDataAction(response.data.data.sessions));
      actions.resetForm({
        title: '',
        topic: '',
      });
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const getAllDropdownData = async () => {
    try {
      const response = await api.get('/event/bd/data');
      dispatch(loadVenueDataAction(response.data.data.venues));
      dispatch(loadFacultyDataAction(response.data.data.speakers));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const closeVenueDialog = () => {
    setisEditDialogOpen(false);
  };
  const closeSpkrConvrDialog = () => {
    setIsSpkrConvrDialogueOpen(false);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const handleDateError = (error) => {
    console.log('Date error:', error);
    // You can also update formik error state here if needed
    if (error) {
      formik.setFieldError('startDateTime', 'Invalid date/time selected');
    }
  };

  useEffect(() => {
    getAllDropdownData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sm>
          <Card>
            <CardHeader
              title="Add a session"
              titleTypographyProps={{
                fontSize: '24px', // Adjust the font size as needed
              }}
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2 }}>
              <StyledForm
                className="employee-form"
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Grid container spacing={{ xs: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      sx={{ marginRight: 2 }}
                      size="large"
                      fullWidth
                      id="title"
                      name="title"
                      label="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>
                  <Grid sx={{ mb: 2 }} item xs={12} md={6}>
                    <TextField
                      sx={{ marginRight: 2 }}
                      size="large"
                      fullWidth
                      id="mobile"
                      multiline
                      name="topic"
                      label="Description"
                      value={formik.values.topic}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.topic && Boolean(formik.errors.topic)
                      }
                      helperText={formik.touched.topic && formik.errors.topic}
                    />
                  </Grid>
                  <Grid sx={{ mb: 2 }} item xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        name="startDateTime"
                        label="Start Date & Time"
                        value={formik.values.startDateTime}
                        onChange={(newValue) =>
                          formik.setFieldValue('startDateTime', newValue)
                        }
                        onBlur={formik.handleBlur} // Trigger validation when leaving the field
                        disablePast
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            name="startDateTime"
                            error={
                              formik.touched.startDateTime &&
                              Boolean(formik.errors.startDateTime)
                            }
                            helperText={
                              formik.touched.startDateTime &&
                              formik.errors.startDateTime
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {formik.touched.startDateTime &&
                      Boolean(formik.errors.startDateTime) && (
                        <Typography sx={{ color: 'red' }}>
                          {formik.errors.startDateTime}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        minutesStep={1}
                        name="endDateTime"
                        label="End Date & Time"
                        value={formik.values.endDateTime}
                        onChange={(newValue) =>
                          formik.setFieldValue('endDateTime', newValue)
                        }
                        disablePast
                        onBlur={() =>
                          formik.setFieldTouched('endDateTime', true)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={
                              formik.touched.endDateTime &&
                              Boolean(formik.errors.endDateTime)
                            }
                            helperText={
                              formik.touched.endDateTime &&
                              formik.errors.endDateTime
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {formik.touched.endDateTime &&
                      Boolean(formik.errors.endDateTime) && (
                        <Typography sx={{ color: 'red' }}>
                          {formik.errors.endDateTime}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={
                        formik.touched.venue && Boolean(formik.errors.venue)
                      }
                    >
                      <InputLabel id="venue">Hall</InputLabel>
                      <Select
                        labelId="venue"
                        id="venue"
                        size="large"
                        name="venue"
                        value={formik.values.venue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {venueData?.map((venue) => (
                          <MenuItem key={venue?._id} value={venue?._id}>
                            {venue?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.venue && formik.errors.venue && (
                        <FormHelperText>{formik.errors.venue}</FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      onClick={() => setisEditDialogOpen(true)}
                      sx={{ float: 'right' }}
                      variant="text"
                    >
                      Add a new hall
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      size="large"
                      error={
                        formik.touched.convenors &&
                        Boolean(formik.errors.convenors)
                      }
                    >
                      <InputLabel id="userType-label">Convenors</InputLabel>
                      <Select
                        labelId="userType-label"
                        id="userType"
                        multiple
                        name="convenors"
                        value={formik.values.convenors}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {facultyData.map((conv) => (
                          <MenuItem key={conv?._id} value={conv?._id}>
                            {conv?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.convenors && formik.errors.convenors && (
                        <FormHelperText>
                          {formik.errors.convenors}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      onClick={() => setIsSpkrConvrDialogueOpen(true)}
                      sx={{ float: 'right' }}
                      variant="text"
                    >
                      Add a new convenor
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      size="large"
                      error={
                        formik.touched.speakers &&
                        Boolean(formik.errors.speakers)
                      }
                    >
                      <InputLabel id="userType-label">Speakers</InputLabel>
                      <Select
                        labelId="userType-label"
                        id="userType"
                        multiple
                        name="speakers"
                        value={formik.values.speakers}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {facultyData.map((spkr) => (
                          <MenuItem key={spkr?._id} value={spkr?._id}>
                            {spkr?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.speakers && formik.errors.speakers && (
                        <FormHelperText>
                          {formik.errors.speakers}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      onClick={() => setIsSpkrConvrDialogueOpen(true)}
                      sx={{ float: 'right' }}
                      variant="text"
                    >
                      Add a new speaker
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <Button
                        sx={{ float: 'right' }}
                        type="submit"
                        startIcon={editData ? <Update /> : <AddIcon />}
                        color="primary"
                        variant="contained"
                        disabled={formik.isSubmitting}
                      >
                        {editData ? 'Update' : 'Add'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </StyledForm>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={isEditDialogOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={closeVenueDialog}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <AddVenueForm editData={null} closeEditDialog={closeVenueDialog} />
        </Box>
      </Modal>
      <Modal
        open={isSpkrConvrDialogueOpen}
        // onClose={closeEditDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* Cross Button */}
          <IconButton
            aria-label="close"
            onClick={closeSpkrConvrDialog}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          {/* Content Inside Modal */}
          <AddFacultyForm
            closeEditDialog={closeSpkrConvrDialog}
            editData={null}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default AddSessionForm;
