import {
  FACULTY_DATA_LOADED,
  FACULTY_ERROR,
  FACULTY_ADDED,
} from './facultyTypes';
const initialState = {
  isLoading: true,
  isError: false,
  facultyData: [],
};

function facultyReducer(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case FACULTY_DATA_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        facultyData: payload,
      };

    case FACULTY_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        facultyData: [],
      };

    case FACULTY_ADDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default facultyReducer;
