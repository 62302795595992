import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
// import { getAccessControlData } from './UtilAccessControl';
import Paper from '@mui/material/Paper';
import api from '../../utilities/api';
import { showSnackbar } from '../../redux/alert/alertAction';
import { loadVenueDataAction } from '../../redux/venue/venueAction';
import DialogAlert from '../alert/DialogueAlert';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import AddVenueForm from '../venue/AddVenueForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

//  for formik

function VenueTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [deleteErr, setdeleteErr] = useState('');

  //   const hospital = useSelector((state) => state.auth.hospital);
  //   const { isLoading, isError, accessControlData } = useSelector(
  //     (state) => state.accessControl
  //   );

  // const handleOpen = () => setOpen(true);
  //   const url = `/hospitals/${hospital._id}/users?type=accesscontrol`;
  //   async function getAccessControlData() {
  //     const response = await api.get(url);
  //     console.log('get accesscontrol data', response);
  //     if (response)
  //       dispatch(loadAccessControlDataAction(response.data.data.users));
  //   }

  // for formik set up

  // delete user

  const deleteVenue = async () => {
    try {
      const response = await api.delete(`venue/${rowToDelete?._id}`);
      dispatch(loadVenueDataAction(response.data.data.venues));
      closeDeleteDialog();
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );
  const { isLoading, isError, venueData } = useSelector((state) => state.venue);

  const dispatch = useDispatch();
  const getVenueData = async () => {
    try {
      const response = await api.get('/venue');

      dispatch(loadVenueDataAction(response.data.data.venues));
    } catch (err) {
      dispatch(showSnackbar(err.response.data.message, 'error'));
    }
  };

  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit(null);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        description: 'Wiroc ID',
        type: 'string',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Name',
        description: 'name',
        type: 'string',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'address',
        headerName: 'Address',
        description: 'Address',
        type: 'string',
        minWidth: 150,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 200,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [deleteVenue, editRow]
  );

  useEffect(() => {
    getVenueData();
    return () => {};
  }, []);

  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6" component="h4">
          Add Hall
        </Typography>

        {/* Subheading */}
        <Typography variant="subtitle1" component="h2" sx={{ color: 'gray' }}>
          Add a hall using +Add button below.
        </Typography>
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'gray', marginBottom: 2 }}
        >
          Each hall will have a seperate schedule.
        </Typography>
        <Button
          type="button"
          startIcon={<AddIcon />}
          color="primary"
          variant="contained"
          onClick={() => setisEditDialogOpen(true)}
        >
          Add
        </Button>
      </Box>
      <Paper
        sx={{
          height: 600,
          width: '100%',
          marginTop: 2,
          // boxShadow: 8,
          padding: 4,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light gray hover effect
              },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          columns={columns}
          rows={venueData}
          onRowClick={(params) => {
            setRowToEdit(params.row);
            setisEditDialogOpen(true);
          }}
        />
        <Modal
          open={isEditDialogOpen}
          // onClose={closeEditDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={closeEditDialog}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <AddVenueForm
              editData={rowToEdit}
              closeEditDialog={closeEditDialog}
            />
          </Box>
        </Modal>
        <DialogAlert
          open={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          handleYes={deleteVenue}
          title={`Delete venue ${rowToDelete?.name}`}
          msg={
            deleteErr ||
            `Are you sure you want to delete venue: ${rowToDelete?.name}?`
          }
        />
      </Paper>
    </>
  );
}

export default VenueTable;
