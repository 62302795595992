import {
  SESSION_DATA_LOADED,
  SESSION_ERROR,
  SESSION_ADDED,
} from './sessionTypes';

export const loadSessionDataAction = (data) => (dispatch) => {
  dispatch({
    type: SESSION_DATA_LOADED,
    payload: data,
  });
};

export const sessionErrorAction = () => (dispatch) => {
  dispatch({
    type: SESSION_ERROR,
  });
};

export const sessionAddedAction = () => (dispatch) => {
  dispatch({
    tyep: SESSION_ADDED,
  });
};
