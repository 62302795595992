import { NOTIFICATION_LOADED } from './notificationType';
const initialState = {
  notificationData: [],
};

function notificationReducer(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case NOTIFICATION_LOADED:
      return {
        ...state,
        notificationData: payload,
      };

    default:
      return {
        ...state,
      };
  }
}

export default notificationReducer;
