import { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import Email from './Email';
// import DDLogo from '../icons/ddLogo';
import MobileEmailOtp from './MobileEmailOtp';
import api from '../../utilities/api';
import setAuthToken from '../../utilities/setAuthToken';
import {
  loginSuccessAction,
  loginFailureAction,
} from '../../redux/auth/authAction';

const pages = ['Enter Mobile Or Email', 'Enter OTP'];

const initialValues = {
  email: '',
  otp: '',
};

const mobileEmailValidation = yup.object({
  email: yup
    .string()
    .email('Please provide a valid email')
    .required('Required'),
});

const otpValidation = yup.object({
  otp: yup.string().required('Required'),
});

const validationSchema = [mobileEmailValidation, otpValidation];

export default function Login() {
  const [currentStep, setCurrentStep] = useState(0);
  const [otpId, setOtpId] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated } = auth;

  const isLastPage = currentStep === pages.length - 1;
  const backStep = (step) => {
    setCurrentStep(step - 1);
  };

  const onSubmit = async (values, actions) => {
    const { email } = values;
    const formData = {
      userDetails: {
        email: email,
      },
    };

    if (currentStep === 0) {
      try {
        const response = await api.post('/auth/email/step1', formData);
        const { data } = response.data;

        setOtpId(data?._id);
        setCurrentStep(currentStep + 1);
      } catch (err) {
        if (err) {
          actions.setFieldError('email', err.response?.data.message);
          dispatch(loginFailureAction());
        }
      }
    } else if (currentStep === 1) {
      try {
        const { otp } = values;
        const formData = {
          otpData: {
            otp: otp,
            _id: otpId,
          },
        };
        const response = await api.post('/auth/email/verify', formData);
        const { data } = response.data;
        console.log({ data });
        dispatch(loginSuccessAction(data));
        navigate('/dashboard');
      } catch (err) {
        if (err) {
          actions.setFieldError('otp', err.response?.data.message);
          dispatch(loginFailureAction());
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[currentStep],
    onSubmit: onSubmit,
  });
  console.log({ formik });
  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <Email formik={formik} />;
      case 1:
        return (
          <MobileEmailOtp
            currentStep={currentStep}
            backStep={backStep}
            formik={formik}
          />
        );

      default:
        return <div>Page Not Found</div>;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'block',
          width: 890,
          minHeight: 520,
          // height: 520,
          backgroundColor: '#fff',
          boxShadow: '0px 2px 30px #ccc6',
          margin: 'auto',
          marginTop: '7%',
          overflow: 'hidden',
        }}
      >
        <>
          <Box
            p={4}
            sx={{
              width: 500,
              minHeight: 520,
              height: 'auto',
              background: '#fff',
              padding: 6,
              borderRadius: 5,
              transition: 'all .1s ease-in-out',
              float: 'left',
              overflowY: 'auto',
              display: 'table-cell',
              borderRight: '2px solid #f1f1f1',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& > :not(style)': {
                  mr: 2,
                  fontSize: '10',
                },
              }}
            >
              {' '}
              <img
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'contain',
                  // borderRadius: '50%',
                  // display: 'inlineBlock',
                  // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                }}
                src={require('../assets/wiroc.png')}
              />
            </Box>
            <Box
              sx={{
                marginBottom: 2,
                marginTop: 2,
              }}
            >
              <Typography variant="h6">Login</Typography>
            </Box>
            <Box>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                {renderPageContent(currentStep)}
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: 3,
                    marginBotton: 3,
                    gap: 2,
                  }}
                >
                  {isLastPage && currentStep > 0 && (
                    <Button
                      variant="outlined"
                      fullWidth
                      size="large"
                      type="button"
                      onClick={() => backStep(currentStep)}
                      className="button button-green"
                    >
                      Back
                    </Button>
                  )}
                  <Box sx={{ width: '100%', position: 'relative' }}>
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      type="submit"
                      // disabled={formik.isSubmitting}
                    >
                      {isLastPage ? 'Submit' : 'Next'}
                    </Button>
                    {formik.isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </form>
            </Box>
            <Box
              sx={{
                marginTop: 4,
                textAlign: 'center',
                color: 'primary',
              }}
            >
              <Box
                sx={{
                  ...(currentStep > 0 && { display: 'none' }),
                }}
              >
                <Typography component="span" variant="body2">
                  Do not have account?
                </Typography>
                <Link
                  href="/signup"
                  m={1}
                  color="primary"
                  variant="body2"
                  underline="hover"
                >
                  Signup
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: 390,
              minHeight: 520,
              height: 'auto',
              float: 'right',
              // padding: 6,
              backgroundColor: '#fff',
              display: 'table-cell',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{
                  width: '400px',
                  height: '520px',
                  objectFit: 'cover',
                  // borderRadius: '50%',
                  display: 'inlineBlock',
                  // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                }}
                src={require('../assets/bg-slider.jpg')}
              />
              {/* <StyledLoginPaper /> */}
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
}
