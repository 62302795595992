import { VENUE_DATA_LOADED, VENUE_ERROR, VENUE_ADDED } from './venueTypes';

export const loadVenueDataAction = (data) => (dispatch) => {
  dispatch({
    type: VENUE_DATA_LOADED,
    payload: data,
  });
};

export const venueErrorAction = () => (dispatch) => {
  dispatch({
    type: VENUE_ERROR,
  });
};

export const venueAddedAction = () => (dispatch) => {
  dispatch({
    tyep: VENUE_ADDED,
  });
};
