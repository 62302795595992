import Signup from '../../components/signup/Signup';
function SignupPage() {
  return (
    <>
      <Signup />
    </>
  );
}

export default SignupPage;
