import {
  SESSION_DATA_LOADED,
  SESSION_ERROR,
  SESSION_ADDED,
} from './sessionTypes';
const initialState = {
  isLoading: true,
  isError: false,
  sessionData: [],
};

function sessionReducer(state = initialState, actions) {
  const { payload, type } = actions;
  switch (type) {
    case SESSION_DATA_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        sessionData: payload,
      };

    case SESSION_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        sessionData: [],
      };

    case SESSION_ADDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default sessionReducer;
